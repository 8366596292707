import React from 'react'
import { Button, Heading, Layout } from "@shopify/polaris";
import VideoCard from './VideoCard';
import _ from "lodash";
import { DndProvider } from 'react-dnd'
import Backend from 'react-dnd-html5-backend'

export default function VideoSection(props) {
    const renderCard = (video, index) => {
        return (
            <VideoCard 
                dragId={video.key}
                index={index} 
                key={video.key}
                moveCard={props.onVideoCardMove}
                onDelete={props.onVideoCardDelete}
                onInputChange={props.onVideoCardInputUpdate}
                onVideoUrlHelperClick={props.onVideoUrlHelperClick}
                video={video} 
            />
        )
    }

    return  (
        <Layout.Section>
            <div style={{borderTop: "0.1rem solid var(--p-border-subdued, #dfe3e8)"}}>
                <div style={{margin: '10px 0'}}>
                    <Heading>Gallery videos</Heading>
                </div>

                {_.isEmpty(props.videos) ? (
                        <p className='Polaris-EmptyState__Content empty-state-msg'>
                            Your gallery is still empty. Click on the "Add video" button to add the first TikTok video to it!
                        </p>
                    ) : (
                        <DndProvider backend={Backend}>
                            {props.videos.map((video, i) => renderCard(video, i))}
                        </DndProvider>
                    )
                }

                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <Button onClick={() => props.onVideoCardAdd()}>Add video</Button>
                </div>
            </div>
        </Layout.Section>
    )
}