import React from 'react';
import { Modal, Stack, TextContainer } from "@shopify/polaris";
import FindUrlCopyIcon from '../_assets/images/find_url_copy_icon.jpg'
import FindUrlVideoCard from '../_assets/images/find_url_video_card.jpg'
import '../_assets/css/Tutorials.css'

export default function FindUrlModal(props) {
    return  (
        <Modal
            large
            open={props.isOpen}
            onClose={() => props.onClose()}
            title="How to find the TikTok video url"
        >
            <Modal.Section>
                <Stack vertical>
                    <TextContainer>
                        <p>1. Go to <a href="https://tiktok.com" target="_blank" rel="noopener noreferrer">https://tiktok.com</a> and search for the video you wish add to your gallery.</p>
                    </TextContainer>

                    <TextContainer>
                        <p>2. Inside the video page, click on the copy icon right bellow the video's description:</p>
                    </TextContainer>

                    <div className="tutorial-image-wrapper">
                        <img className="tutorial-image" src={FindUrlCopyIcon} alt="step-1"/>
                    </div>

                    <TextContainer>
                        <p>3. Paste the copied url in the "TikTok video url" field:</p>
                    </TextContainer>

                    <div className="tutorial-image-wrapper">
                        <img className="tutorial-image" src={FindUrlVideoCard} alt="step-2"/>
                    </div>

                    <TextContainer>
                        <p>4. If the copied link is correct you will see the video's thumbnail on the left side of the card! You can also drag and drop the videos to reorder them.</p>
                    </TextContainer>

                </Stack>
            </Modal.Section>
        </Modal>
    )
}