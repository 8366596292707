import React from 'react';
import { Modal, Stack, TextContainer } from "@shopify/polaris";
import Step1Image from '../_assets/images/install_tutorial_1.jpg'
import Step2Image from '../_assets/images/install_tutorial_2.jpg'
import Step3Image from '../_assets/images/uninstall_tutorial_3.jpg'
import Step4Image from '../_assets/images/uninstall_tutorial_4.jpg'
import '../_assets/css/Tutorials.css'

export default function HowToUninstallModal(props) {
    return  (
        <Modal
            large
            open={props.isOpen}
            onClose={() => props.onClose()}
            title="How to remove the TikTok Shopping Gallery"
        >
            <Modal.Section>
                <Stack vertical>
                    <TextContainer>
                        <p>1. Go to your Shopify admin and click on the "Themes" option inside the "Online Store" menu section:</p>
                    </TextContainer>
                    
                    <div className="tutorial-image-wrapper">
                        <img className="tutorial-image" src={Step1Image} alt="step-1"/>
                    </div>

                    <TextContainer>
                        <p>2. Click on the "Customize" button of your current theme:</p>
                    </TextContainer>

                    <div className="tutorial-image-wrapper">
                        <img className="tutorial-image" src={Step2Image} alt="step-2"/>
                    </div>

                    <TextContainer>
                        <p>3. Make sure the "Sections" tab is selected and click on the TikTok Shopping Galley section you've added on the installation steps:</p>
                    </TextContainer>

                    <div className="tutorial-image-wrapper">
                        <img className="tutorial-image" src={Step3Image} alt="step-3"/>
                    </div>

                    <TextContainer>
                        <p>4. Click on the "Remove section" button:</p>
                    </TextContainer>

                    <div className="tutorial-image-wrapper">
                        <img className="tutorial-image" src={Step4Image} alt="step-4"/>
                    </div>

                    <TextContainer>
                        <p>5. And that's it! Now you can uninstall the TikTok Shopping Galley on your store's admin panel.</p>
                    </TextContainer>

                    <TextContainer>
                        <p>Don't hesitate to <a href="mailto:support@tordenapps.com">contact us</a> if you have any questions or need any help. We will be glad to help!</p>
                    </TextContainer>
                </Stack>
            </Modal.Section>
        </Modal>
    )
}