import React from 'react';
import { Modal, Stack, TextContainer } from "@shopify/polaris";
import Step1Image from '../_assets/images/install_tutorial_1.jpg'
import Step2Image from '../_assets/images/install_tutorial_2.jpg'
import Step3Image from '../_assets/images/install_tutorial_3.jpg'
import Step4Image from '../_assets/images/install_tutorial_4.jpg'
import Step5Image from '../_assets/images/install_tutorial_5.jpg'
import Step6Image from '../_assets/images/install_tutorial_6.jpg'
import Step7Image from '../_assets/images/install_tutorial_7.jpg'
import Step8Image from '../_assets/images/install_tutorial_8.jpg'
import Step9Image from '../_assets/images/install_tutorial_9.jpg'
import '../_assets/css/Tutorials.css'

export default function HowToInstallModal(props) {
    return  (
        <Modal
            large
            open={props.isOpen}
            onClose={() => props.onClose()}
            title="How to install the TikTok Shopping Gallery on your store"
        >
            <Modal.Section>
                <Stack vertical>
                    <TextContainer>
                        <p>1. Go to your Shopify admin and click on the "Themes" option inside the "Online Store" menu section:</p>
                    </TextContainer>
                    
                    <div className="tutorial-image-wrapper">
                        <img className="tutorial-image" src={Step1Image} alt="step-1"/>
                    </div>

                    <TextContainer>
                        <p>2. Click on the "Customize" button of your current theme:</p>
                    </TextContainer>

                    <div className="tutorial-image-wrapper">
                        <img className="tutorial-image" src={Step2Image} alt="step-2"/>
                    </div>

                    <TextContainer>
                        <p>3. Make sure the "Sections" tab is selected and click on the "Add section" option:</p>
                    </TextContainer>

                    <div className="tutorial-image-wrapper">
                        <img className="tutorial-image" src={Step3Image} alt="step-3"/>
                    </div>

                    <TextContainer>
                        <p>4. Scroll the menu to the bottom and click on "Custom content":</p>
                    </TextContainer>

                    <div className="tutorial-image-wrapper">
                        <img className="tutorial-image" src={Step4Image} alt="step-4"/>
                    </div>

                    <TextContainer>
                        <p>5. Click on the "Add" button that is going to show up inside "Custom content":</p>
                    </TextContainer>

                    <div className="tutorial-image-wrapper">
                        <img className="tutorial-image tutorial-image-small" src={Step5Image} alt="step-5"/>
                    </div>

                    <TextContainer>
                        <p>6. Click on "Add content" and select "Custom HTML":</p>
                    </TextContainer>

                    <div className="tutorial-image-wrapper">
                        <img className="tutorial-image" src={Step6Image} alt="step-6"/>
                    </div>

                    <TextContainer>
                        <p>7. Copy and paste the code bellow inside the "HTML" field:</p>
                        <p className="tutorial-code-wrapper">
                            <code>{'<div id="torden-tiktok-gallery"></div>'}</code>
                        </p>
                    </TextContainer>

                    <div className="tutorial-image-wrapper">
                        <img className="tutorial-image" src={Step7Image} alt="step-7"/>
                    </div>

                    <TextContainer>
                        <p>8. Depending on your theme you might see the "Container width" option right bellow the "HTML" field. If you see this option make sure to select "100%" on it:</p>
                    </TextContainer>

                    <div className="tutorial-image-wrapper">
                        <img className="tutorial-image tutorial-image-small" src={Step8Image} alt="step-8"/>
                    </div>

                    <TextContainer>
                        <p>9. Click on the "Save" button and head back to your store to check out your gallery!</p>
                    </TextContainer>

                    <div className="tutorial-image-wrapper">
                        <img className="tutorial-image" src={Step9Image} alt="step-9"/>
                    </div>

                    <TextContainer>
                        <p>Don't hesitate to <a href="mailto:support@tordenapps.com">contact us</a> if you have any questions or need any help on how to install and configure your TikTok Gallery. We will be glad to help!</p>
                    </TextContainer>
                </Stack>
            </Modal.Section>
        </Modal>
    )
}