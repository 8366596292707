import React, { Component } from 'react';
import { CalloutCard, Frame, Layout, Loading, Page, PageActions, Toast } from "@shopify/polaris";
import { firebaseService } from "./_services/firebaseService";
import errorCodes from "./_errors/errorCodes";
import _ from 'lodash';
import VideoSection from './_components/VideoSection';
import shortid from 'shortid';
import update from 'immutability-helper'
import FindUrlModal from './_components/FindUrlModal';
import HowToInstallModal from './_components/HowToInstallModal';
import './_assets/css/App.css'
import HowToUninstallModal from './_components/HowToUninstallModal';

const REQUIRED_MESSAGE = "This field is required";

export default class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      shopData: {
        videos: []
      },
      isFirstLoading: true,
      isSaving: false,
      showFindUrlModal: false,
      showHowToInstallModal: false,
      showHowToUninstallModal: false,
      toast: {
        content: '',
        error: false,
        show: false
      }
    };
  }

  toogleToastVisibility = (content = '', error = false) => {
    this.setState({
      toast: {
        content,
        error,
        show: !this.state.toast.show
      }
    })
  }

  updateInput = (key, value) => {
    this.setState({
      shopData: {
        ...this.state.shopData,
        [key]: value
      }
    });
  }

  handleAddVideoCard = () => {
    let videosCopy = JSON.parse(JSON.stringify(this.state.shopData.videos));
    videosCopy.push({ key: shortid.generate() });

    this.setState({
      shopData: {
        ...this.state.shopData,
        videos: videosCopy
      }
    });
  }

  handeVideoCardInputUpdate = (key, value, index) => {
    let videosCopy = JSON.parse(JSON.stringify(this.state.shopData.videos));

    if(videosCopy[index]) {
      videosCopy[index][key] = value;

      this.setState({
        shopData: {
          ...this.state.shopData,
          videos: videosCopy
        }
      });
    }
  }

  handleVideoCardDelete = (index) => {
    let videosCopy = JSON.parse(JSON.stringify(this.state.shopData.videos));
    videosCopy.splice(index, 1);

    this.setState({
      shopData: {
        ...this.state.shopData,
        videos: videosCopy
      }
    });
  }

  handleVideoCardMove = (dragIndex, hoverIndex) => {
    let videosCopy = JSON.parse(JSON.stringify(this.state.shopData.videos));
    const dragCard = videosCopy[dragIndex];

    videosCopy =
      update(videosCopy, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard],
        ],
      })

    this.setState({
      shopData: {
        ...this.state.shopData,
        videos: videosCopy
      }
    });
  }

  componentDidMount = () => {
    // If we don't have a shopOrigin, redirect to the error page
    if(_.isEmpty(this.props.shopOrigin)) {
      window.location.href = `/?error=${errorCodes.MISSING_SHOP_PARAMETER_ON_APP}`;
      return;
    }

    firebaseService.getShopData(this.props.shopOrigin).then(shopData => {
      if(_.isEmpty(shopData)) {
        window.location.href = `/?error=${errorCodes.ERROR_GETTING_SHOP_DATA}`;
        return;
      }

      // If we have any videos, add a random key to them 
      // that is going to be used by react
      if(!_.isEmpty(shopData.videos)) {
        shopData.videos.forEach(video => {
          video['key'] = shortid.generate();
        })
      }

      this.setState({
        shopData: {
          ...this.state.shopData,
          ...shopData
        },
        isFirstLoading: false
      })
    })
  }

  validateVideoProperties = () => {
    let videosCopy = JSON.parse(JSON.stringify(this.state.shopData.videos));
    let anyVideoHasErrors = false;
    
    videosCopy.forEach(video => {

      video.errors = {};

      if(_.isEmpty(video.thumbnailUrl) || _.isEmpty(video.videoUrl)) {
        video.errors = { ...video.errors, videoUrl: 'The video url is invalid' }
      }

      if(video.callToActionType === 'custom_url_button' && _.isEmpty(video.buttonText)) {
        video.errors = { ...video.errors, buttonText: REQUIRED_MESSAGE }
      }

      if(video.callToActionType === 'custom_url_button' && _.isEmpty(video.buttonUrl)) {
        video.errors = { ...video.errors, buttonUrl: REQUIRED_MESSAGE }
      }

      const videoHasErrors = Object.keys(video.errors).length > 0;

      if(!videoHasErrors) {
        delete video.errors;
      } else {
        anyVideoHasErrors = true;
      }
    })

    return {
      hasErrors: anyVideoHasErrors,
      videos: videosCopy
    }
  }

  save = () => {
    // Check any property of this.state.errors is true (if so, there is an error)
    const validation = this.validateVideoProperties();

    this.setState({
      shopData: {
        ...this.state.shopData,
        videos: validation.videos
      }
    }, () => {

      if(validation.hasErrors) {
        this.toogleToastVisibility('Check your videos configuration before saving', true);
        return;
      } 

      this.setState({isSaving : true});

      firebaseService.saveShopData(this.props.shopOrigin, this.state.shopData)
        .then(() => {
          this.setState({isSaving : false});
          this.toogleToastVisibility('Configuration updated');
        })
        .catch(() => {
          this.setState({isSaving : false});
          this.toogleToastVisibility('Error on saving', true);
        });
    })
  }

  toggleFindUrlModal = () => {
    this.setState({
      ...this.state,
      showFindUrlModal: !this.state.showFindUrlModal
    })
  }

  toggleHowToInstallModal = () => {
    this.setState({
      ...this.state,
      showHowToInstallModal: !this.state.showHowToInstallModal
    })
  }

  toggleHowToUninstallModal = () => {
    this.setState({
      ...this.state,
      showHowToUninstallModal: !this.state.showHowToUninstallModal
    })
  }

  render() {
    if(this.state.isFirstLoading) {
      return (
        <Frame>
          <Loading />
        </Frame>
      )
    }

    const { shopData, toast } = this.state;

    return (
      <Frame>
        <Page>
          {toast.show && 
            <Toast content={toast.content} error={toast.error} duration={5000} onDismiss={this.toogleToastVisibility} />
          }

          <Layout>

            <Layout.Section>
              <CalloutCard
                title="Install your gallery"
                illustration="https://cdn.shopify.com/s/assets/admin/checkout/settings-customizecart-705f57c725ac05be5a34ec20c05b94298cb8afd10aac7bd9c7ad02030f48cfa0.svg"
                primaryAction={{
                  content: 'How to install',
                  onAction: () => this.toggleHowToInstallModal()
                }}
              >
                <p>The TikTok Shopping Gallery is compatible with all store themes! Click on the button bellow to see how to install it on your store.</p>
                <p>If you have any feedback or suggestions, or need some help on installing and configuring your gallery, fell free to <a href="mailto:support@tordenapps.com">contact us</a>. We will be glad to hear from you!</p>
              </CalloutCard>
            </Layout.Section>
            
            <Layout.Section>
              <p onClick={() => this.toggleHowToUninstallModal()} class='uninstall-tutorial-link'>Would you like to uninstall the app? Make sure to click here and check the removal steps!</p>
            </Layout.Section>

            <VideoSection 
              onVideoCardAdd={this.handleAddVideoCard}
              onVideoCardDelete={this.handleVideoCardDelete}
              onVideoCardInputUpdate={this.handeVideoCardInputUpdate} 
              onVideoCardMove={this.handleVideoCardMove}
              onVideoUrlHelperClick={this.toggleFindUrlModal}
              videos={shopData.videos}
            />

            <Layout.Section>
              <PageActions
                primaryAction={{
                  loading: this.state.isSaving,
                  content: 'Save',
                  onAction: this.save
                }}
              />
            </Layout.Section>
          </Layout>
        </Page>
        
        <FindUrlModal isOpen={this.state.showFindUrlModal} onClose={this.toggleFindUrlModal} />
        <HowToInstallModal isOpen={this.state.showHowToInstallModal} onClose={this.toggleHowToInstallModal} />
        <HowToUninstallModal isOpen={this.state.showHowToUninstallModal} onClose={this.toggleHowToUninstallModal} />

      </Frame>
    );
  }
}
