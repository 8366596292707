import React, { useState, useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { Card, FormLayout, Icon, Select, TextField } from "@shopify/polaris";
import LoadingSpinner from '../_assets/images/loading_spinner.gif'
import VideoPlaceholder from '../_assets/images/video_placeholder.png'
import { DeleteMajorMonotone } from '@shopify/polaris-icons';
import { tiktokService } from '../_services/tiktokService';
import '../_assets/css/VideoCard.css'
import _ from "lodash";

export default function VideoCard({video, onInputChange, moveCard, index, dragId, onDelete, onVideoUrlHelperClick}) {
    const [loading, setLoading] = useState(false);

    const loadVideoData = () => {
        setLoading(true);
        
        tiktokService.getEmbedVideoData(video.videoUrl).then(videoData => {
            // Check for error on response
            if(videoData.data && videoData.data.status_msg) {
                // Clear the current video thumbnailUrl
                onInputChange('thumbnailUrl', '', index);
                onInputChange('errors', { ...video.errors, videoUrl: 'The video url is invalid' }, index);
                return;
            }

            // Set the video thumbnailUrl
            onInputChange('thumbnailUrl', videoData.data.thumbnail_url, index);
            onInputChange('errors', { ...video.errors, videoUrl: '' }, index);
        })
        .catch(() => {
            // Clear the current video thumbnailUrl
            onInputChange('thumbnailUrl', '', index);
            onInputChange('errors', { ...video.errors, videoUrl: 'The video url is invalid' }, index);
        })
        .finally(() => {
            setLoading(false)
        })
    }

    // -------------------------------------
    // ----------- Drag and drop -----------
    // -------------------------------------

    const ref = useRef(null)
    const [, drop] = useDrop({
      accept: 'card',
      hover(item, monitor) {
        if (!ref.current) {
          return
        }
        const dragIndex = item.index
        const hoverIndex = index
        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return
        }
        // Determine rectangle on screen
        const hoverBoundingRect = ref.current.getBoundingClientRect()
        // Get vertical middle
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
        // Determine mouse position
        const clientOffset = monitor.getClientOffset()
        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top
        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%
        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return
        }
        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return
        }
        // Time to actually perform the action
        moveCard(dragIndex, hoverIndex)
        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        item.index = hoverIndex
      },
    })
    const [{ isDragging }, drag] = useDrag({
      item: { type: 'card', id: dragId, index: index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    })
    const opacity = isDragging ? 0 : 1
    drag(drop(ref))

    // -------------------------------------
    // -------------------------------------
    // -------------------------------------

    return  (
        <div className='video-card-wrapper' ref={ref} style={{ opacity }}>
            <Card sectioned>
                <div className='video-card-content-wrapper'>
                    <div className='video-placeholder-wrapper'>
                        <div className='loading-wrapper' style={{ display: loading ? 'flex' : 'none'}}>
                            <img src={LoadingSpinner} alt="loading"/>
                        </div>
                        <img src={_.isEmpty(video.thumbnailUrl) ? VideoPlaceholder : video.thumbnailUrl} className='video-placeholder' style={{ opacity: loading ? '0' : '1'}} alt="video thumbnail" />
                    </div>
                    <div className='video-card-form'>
                        <FormLayout>
                            <TextField 
                                error={video.errors ? video.errors.videoUrl : ''}
                                label="TikTok video url" 
                                labelAction={{content: 'Where can I find this url?', onAction: () => onVideoUrlHelperClick()}}
                                onBlur={(value) => loadVideoData()}
                                onChange={value => onInputChange('videoUrl', value, index)}
                                placeholder={'Example: https://www.tiktok.com/@scout2015/video/6718335390845095173'}
                                value={video.videoUrl} 
                            />

                            <Select
                                label="Call to action type"
                                onChange={value => onInputChange('callToActionType', value, index)}
                                options={[
                                    {label: 'Keep the default "Discover more on TikTok" button', value: 'tiktok_default'},
                                    {label: 'Include a button with a custom url', value: 'custom_url_button'},
                                ]}
                                value={video.callToActionType} 
                            />

                            {video.callToActionType === 'custom_url_button' && 
                                <TextField 
                                    error={video.errors ? video.errors.buttonText : ''}
                                    label="Button text" 
                                    onChange={value => onInputChange('buttonText', value, index)}
                                    placeholder={'Example: Shop the look'}
                                    value={video.buttonText} 
                                />
                            }

                            {video.callToActionType === 'custom_url_button' && 
                               <TextField 
                                    error={video.errors ? video.errors.buttonUrl : ''}
                                    label="Button url" 
                                    onChange={value => onInputChange('buttonUrl', value, index)}
                                    placeholder={'Example: https://myshop.com/collections/tiktoklook'}
                                    value={video.buttonUrl} 
                                />
                            }
                        </FormLayout>

                        <div className='delete-wrapper' onClick={() => onDelete(index)}>
                            <Icon source={DeleteMajorMonotone} />
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    )
}